import React from 'react';

import styles from '../../components/404Style/index.module.scss';
import { Meta } from '../../components/Meta';
import { redirect } from '../../utils/navigation';

export default function Page404() {
    return (
        <>
            <Meta />
            <section className={styles.page_404}>
                <div className={styles.four_zero_four_bg}>
                    <h1 style={{ textAlign: 'center' }}>404</h1>
                </div>
                <div className={styles.contant_box_404}>
                    <h3>Возникла небольшая ошибка</h3>
                    <p>попробуйте перейти на главную страницу</p>
                    <a onClick={() => redirect('/')} className={styles.link_404}>
            Перейти
                    </a>
                </div>
            </section>
        </>
    );
}
